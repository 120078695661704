<!-- <div>
  <div style="position: absolute;">
    <img src="../../assets/images/logo_clinica.jpg" alt="machadolemos" height=100>
  </div>
  <div [id]="localCallId" class="local"></div>
  <div class="remote">
    A carregar vídeo... Por favor aguarde...
  </div>
  <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote"></div>
</div> -->
<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mode == 0}" (click)="mode = 0">Sessão</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mode == 1}"
              (click)="mode = 1; getFiles();">Ficheiros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mode == 2}" (click)="mode = 2">Chat</a>
          </li>
        </ul>
        <!-- SESSION -->
        <div *ngIf="session != undefined && mode == 0" style="overflow-y: scroll; max-height: 90vh;">
          <form style="margin-top:20px;">
            <div class="form-group">
              <label for="inputMotive">Motivo da sessão</label>
              <textarea [disabled]="session.id != todaySessionId" [(ngModel)]="session.motive" type="text"
                class="form-control" id="inputMotive" placeholder="Coloque aqui o motivo da sessão"
                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div class="form-group">
              <label for="inputNotes">Observações</label>
              <textarea [disabled]="session.id != todaySessionId" [(ngModel)]="session.notes" type="text"
                class="form-control" id="inputNotes" placeholder="Coloque aqui qualquer observação que ache pertinente"
                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div>
              <button *ngIf="session.id == todaySessionId" type="submit" (click)="updateSession()"
                class="btn btn-success" style="float:right;">Guardar</button>
              <button *ngIf="session.id == todaySessionId && session.state < 2" type=" submit" (click)="closeSession()"
                class="btn btn-warning" style="float:right;">Terminar</button>
              <button *ngIf="session.id == todaySessionId && session.state == 2" type="submit" (click)="exitSession()"
                class="btn btn-warning" style="float:right;">Sair</button>
            </div>

          </form>


        </div>
        <!-- FILES -->
        <div *ngIf="session != undefined && mode == 1" style="overflow-y: scroll; max-height: 90vh;">
          <!-- <div *ngFor="let file of files">{{file.name}} | {{file.metadata.customMetadata.description}} | {{file.url}}
          </div> -->
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Descrição</th>
                <!-- <th scope="col">Data</th> -->
                <th scope="col">Acção</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of files">
                <td *ngIf="file.metadata.customMetadata != undefined">{{file.metadata.customMetadata.description}}</td>
                <td *ngIf="file.metadata.customMetadata == undefined">Carregar informação...</td>
                <!-- <th>{{file.date}}</th> -->
                <td *ngIf="file.url != undefined">
                  <a href="{{file.url}}" target="_blank"><button type="submit"
                      class="btn btn-primary btn-sm">Obter</button></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="max-height: 90vh;" *ngIf="session != undefined && mode == 2">
          <div style="height:80vh;overflow-y: scroll;">
            <div class="chat-container" *ngFor="let message of messages"
              [ngClass]="{'other': (message.ownerId != session.userId)}">
              <p class="message">{{message.content}}</p>
              <div>
                <span class="date-time">{{message.getDate()}}</span>
                <span class="username">por {{message.name}}</span>
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
          <div style="height:10vh;">
            <input type="text" [(ngModel)]="message" class="form-control" id="inputMessage"
              style="width:80%;float:left;" placeholder="Escreva aqui a mensagem" [ngModelOptions]="{standalone: true}"
              (keyup.enter)="sendMessage()" />

            <button type="submit" [disabled]="message == undefined || message.trim() == ''" (click)="sendMessage()"
              class="btn btn-primary" style="width:20%;float:right;">Enviar</button>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="remote" *ngIf="remoteCalls.length == 0" style="margin-top:20px; padding-top:50px;">
          {{videoWarning}}
        </div>
        <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote"></div>
        <div [id]="localCallId" class="local"></div>
        <div>
          <input type="file" (change)="onChangeFileToUpload($event)" accept=".png,.jpg,.pdf" />
          <input type="text" [(ngModel)]="fileDescription" class="form-control" id="inputFileDescription"
            placeholder="Coloque aqui a descrição do ficheiro" [ngModelOptions]="{standalone: true}" />
          <button type="submit" [disabled]="filename == undefined || fileDescription == undefined"
            (click)="uploadFile()" class="btn btn-success">Guardar
            Ficheiro</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="loading-screen" *ngIf="isLoading">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="text">
    A carregar... por favor aguarde..
  </div>
</div>