<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div style="float: left;">
            Gerir Sessão
          </div>
          <div style="float: right;">
            <a href="/sessions"><button class="btn btn-secondary btn-sm" style="margin-left: 5px;"> Voltar a
                Sessões</button></a>
          </div>
          <div style="clear: both"></div>
        </div>
        <ul *ngIf="session != undefined && session.state >= 2 && isDoctor()" class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mode == 0}" (click)="mode = 0">Sessão</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mode == 1}"
              (click)="mode = 1; getFiles();">Ficheiros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mode == 2}" (click)="mode = 2">Chat</a>
          </li>
        </ul>
        <div *ngIf="session != undefined && mode == 0" class="card-body">
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="inputScheduledDate">Data</label>
              <input disabled='{{session.state >= 2}}' [ngModel]="session.scheduledDate | date:'yyyy-MM-dd'"
                (ngModelChange)="onScheduledDateChange($event)" type="date" class="form-control" id="inputScheduledDate"
                [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-group col-sm-6">
              <label for="inputScheduledTime">Hora</label>
              <select [disabled]='session.state >= 2' class="form-control" [value]="selectedHoursIndex"
                id="inputScheduledTime" (change)="onScheduledTimeChange($event.target.value)">
                <option *ngFor="let hour of hours; let i = index" [selected]="selectedHoursIndex == i" value="{{i}}">
                  {{hour}}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="inputMotive">Motivo</label>
            <input disabled='{{session.state >= 2}}' [(ngModel)]="session.motive" type="text" class="form-control"
              id="inputMotive" [ngModelOptions]="{standalone: true}" />
          </div>
          <div>
            <div *ngIf="session.pin" style="float:left;">
              <div>Pin Gerado: <p>{{session.pin}}</p>
              </div>
            </div>
            <button [disabled]='session.state >= 2' type="submit" (click)="updateSession()" class="btn btn-success"
              style="float:right;">Guardar
              sessão</button>
          </div>
        </div>
        <div *ngIf="session != undefined && mode == 1">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Descrição</th>
                <th scope="col">Acção</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of files">
                <td *ngIf="file.metadata.customMetadata != undefined">{{file.metadata.customMetadata.description}}</td>
                <td *ngIf="file.url != undefined">
                  <a href="{{file.url}}" target="_blank"><button type="submit"
                      class="btn btn-primary btn-sm">Obter</button></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="max-height: 70vh;" *ngIf="session != undefined && mode == 2">
          <div style="height:70vh;overflow-y: scroll;">
            <div class="chat-container" *ngFor="let message of messages"
              [ngClass]="{'other': (message.ownerId != session.userId)}">
              <p class="message">{{message.content}}</p>
              <div>
                <span class="date-time">{{message.getDate()}}</span>
                <span class="username">por {{message.name}}</span>
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading-screen" *ngIf="isLoading">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="text">
    A carregar... por favor aguarde..
  </div>
</div>