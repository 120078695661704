<div style="background-color: #e4e5e6;height:100%;">
  <div *ngIf="isMobile">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mobileTab == 0}"
          (click)="mobileTab = 0">Vídeo</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mobileTab == 1}"
          (click)="mobileTab = 1">Mensagens</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: mobileTab == 2}"
          (click)="mobileTab = 2">Ficheiros</a>
      </li>
    </ul>
  </div>
  <div *ngIf="!isMobile">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: tab == 0}" (click)="tab = 0">Mensagens</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: tab == 1}" (click)="tab = 1">Ficheiros</a>
      </li>
    </ul>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div *ngIf="(!isMobile && tab == 0) || mobileTab == 1" class="col-sm-4">
        <div style="max-height: 90vh;">
          <div class="chat-wrapper" [ngClass]="{'chat-mobile': isMobile}">
            <div class="chat-container" *ngFor="let message of messages"
              [ngClass]="{'other': (message.ownerId == session.userId), 'sys': (message.ownerId == 'system')}">
              <p class="message">{{message.content}}</p>
              <div>
                <span class="date-time">{{message.getDate()}}</span>
                <span class="username">por {{message.name}}</span>
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
          <div style="height:10vh;">
            <input type="text" [(ngModel)]="message" class="form-control" id="inputMessage"
              style="width:80%;float:left;" placeholder="Escreva aqui a mensagem" [ngModelOptions]="{standalone: true}"
              (keyup.enter)="sendMessage()" />

            <button type="submit" [disabled]="message == undefined || message.trim() == ''" (click)="sendMessage()"
              class="btn btn-primary" style="width:20%;float:right;">Enviar</button>
          </div>
        </div>
      </div>
      <div *ngIf="session != undefined && ((!isMobile && tab == 1) || mobileTab == 2)" class="col-sm-4">
        <div style="height: 90vh;overflow-y: scroll;">
          <!-- <div *ngFor="let file of files">{{file.name}} | {{file.metadata.customMetadata.description}} | {{file.url}}
            </div> -->
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Descrição</th>
                <!-- <th scope="col">Data</th> -->
                <th scope="col">Acção</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of files">
                <td *ngIf="file.metadata.description != undefined">{{file.metadata.description}}
                </td>
                <!-- <th>{{file.date}}</th> -->
                <td *ngIf="file.url != undefined">
                  <button (click)="downloadFile(file)" type="submit" class="btn btn-primary btn-sm">Obter</button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="col-sm-8">
        <div class="remote" *ngIf="remoteCalls.length == 0" [ngClass]="{'remote-mobile': isMobile}"
          style="padding-top:50px;">
          {{videoWarning}}
        </div>
        <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote" [ngClass]="{'remote-mobile': isMobile}">
        </div>
        <div [id]="localCallId" class="local" [ngClass]="{'local-mobile': isMobile}"></div>
        <div>
          <input type="file" (change)="onChangeFileToUpload($event)" />
          <input type="text" [(ngModel)]="fileDescription" class="form-control" id="inputFileDescription"
            placeholder="Pode colocar aqui a descrição do ficheiro anexado" [ngModelOptions]="{standalone: true}" />
          <button type="submit" [disabled]="filename == undefined" (click)="uploadFile()"
            class="btn btn-success">Guardar
            Ficheiro</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="loading-screen" *ngIf="isLoading">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="text">
    A carregar... por favor aguarde..
  </div>
</div>