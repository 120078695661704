import { Utils } from '../utils/utils';

export class Message {
  id: string;
  ownerId: string;
  content: string;
  type: number;
  date: number;
  name: string;

  constructor(firebaseObject?) {
    if (firebaseObject !== undefined) {
      this.id = firebaseObject.id;
      this.ownerId = firebaseObject.ownerId;
      this.type = firebaseObject.type;
      this.date = firebaseObject.date;
      this.content = firebaseObject.content;
      this.name = firebaseObject.name;
    }
  }

  static prepareMessageToSend(ownerId: string, type: number, content: string, name: string): Message {
    const msg = new Message();
    msg.date = new Date().getTime();
    msg.ownerId = ownerId;
    msg.content = content;
    msg.name = name;
    return msg;
  }

  export(): object {
    const obj = Object.assign({}, this);
    Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});
    delete obj.id;

    return obj;
  }

  getDate(): string {
    const date = new Date(this.date);
    return ' às ' + Utils.getTime(date);
  }
}
