import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SessionComponent } from './sessions/session/session.component';
import { LoginComponent } from './login/login.component';
import { SessionsComponent } from './sessions/sessions.component';
import { HostSessionComponent } from './sessions/host-session/host-session.component';
import { AddSessionComponent } from './sessions/add-session/add-session.component';
import { AuthGuard } from './utils/auth.guard';
import { SetupComponent } from './sessions/setup/setup.component';
import { RegisterComponent } from './register/register.component';
import { MarketingComponent } from './marketing/marketing.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'sessions/session/:hash', component: SessionComponent },
  { path: 'sessions/setup', component: SetupComponent },
  { path: 'sessions/setup/:hash', component: SetupComponent },
  { path: 'sessions', component: SessionsComponent, canActivate: [AuthGuard] },
  { path: 'sessions/host-session/:hash', component: HostSessionComponent, canActivate: [AuthGuard] },
  { path: 'sessions/add-session', component: AddSessionComponent, canActivate: [AuthGuard] },
  { path: 'sessions/manage-session/:id', component: AddSessionComponent, canActivate: [AuthGuard] },
  { path: ':hash', redirectTo: 'sessions/setup/:hash' },

  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
