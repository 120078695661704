export class Role {
  name: string;
  description: string;
  code: string;


  constructor(name: string, description: string, code: string) {
    this.name = name;
    this.description = description;
    this.code = code;
  }


  static getRoles(): Role[] {
    return [
      Role.doctorRole(),
      Role.assistantRole(),
      Role.adminRole(),
      Role.patientRole()
    ];
  }


  static adminRole(): Role {
    return new Role('Administrador', '', 'admin');
  }

  static doctorRole(): Role {
    return new Role('Médico', '', 'doctor');
  }

  static assistantRole(): Role {
    return new Role('Assistente', '', 'assistent');
  }

  static patientRole(): Role {
    return new Role('Paciente', '', 'patient');
  }
}
