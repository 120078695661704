import { Component, OnInit, HostListener } from '@angular/core';
import { AgoraClient, NgxAgoraService, ClientEvent, StreamEvent, Stream } from 'ngx-agora';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/utils/database.service';
import { Session } from 'src/app/models/session.model';
import { AuthService } from 'src/app/utils/auth.service';
import { Message } from 'src/app/models/message.model';
import { BaseComponent } from 'src/app/utils/base/base.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent extends BaseComponent implements OnInit {

  isMobile = false;
  mobileTab = 0;
  tab = 0;

  title = 'angular-video';
  localCallId = 'agora_local';
  videoWarning = 'A carregar vídeo... Por favor aguarde...';
  remoteCalls: string[] = [];

  messages: Message[];
  message: string;

  session: Session;

  private client: AgoraClient;
  private localStream: Stream;
  private uid: number;
  private intervalMessages;
  private intervalSession;
  private hasStarted = false;


  sessionHash: string;
  validSession = false;


  filename;
  fileDescription;
  files = [];

  timerForMessagesSet = false;
  timerForSessionSet = false;

  initRetries = 0;

  constructor(private ngxAgoraService: NgxAgoraService, private activatedRoute: ActivatedRoute, private db: DatabaseService, private auth: AuthService, router: Router, private deviceDetector: DeviceDetectorService) {
    super(router);
    this.isMobile = this.deviceDetector.isMobile();

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const hash = params.hash;
      if (hash !== undefined) {
        this.sessionHash = hash;
        this.enableSpinner();
        this.fetchInformationAboutSession();
      } else {
        alert('Sessão inválida');
        this.navigateToPage('home');
      }
    });
  }

  fetchInformationAboutSession() {

    this.db.getSessionByHashClient(this.sessionHash).then(data => {
      if (data !== undefined) {
        this.validSession = true;
        this.disableSpinner();
        this.session = Session.initClientSession(data);
        if (this.session.state === 2) {
          clearInterval(this.intervalMessages);
          clearInterval(this.intervalSession);
          if (this.localStream !== undefined) {
            this.localStream.disableVideo();
            this.localStream.disableAudio();
            this.localStream.stop();
            this.localStream.close();
          }
          this.session.visitor = true;
          this.db.updateSessionClient(this.session);
          alert('O médico terminou a sessão. Obrigado.');
          this.navigateToPage('home');
        }
        this.session.visitor = true;
        this.db.updateSessionClient(this.session);
        this.fetchMessages();
        this.getFiles();
        if (this.timerForMessagesSet === false) {
          this.timerForMessagesSet = true;
          this.intervalMessages = setInterval(() => {
            this.fetchMessages();
          }, 2000);
        }

        if (this.timerForSessionSet === false) {
          this.timerForSessionSet = true;
          this.intervalSession = setInterval(() => {
            this.fetchInformationAboutSession();
          }, 5000);
        }
      } else {
        alert('Sessão inválida');
        this.navigateToPage('home');
      }
    });
  }

  fetchMessages() {
    this.db.getMessagesClient(this.session.id, this.session.pin).then((msgs: any) => {
      this.messages = msgs.map(e => {
        const msg = new Message(e);
        return msg;
      });
      if (this.isHostInSession() && !this.hasStarted) {
        this.start();
      } else if (this.messages.length === 0) {
        this.messages.unshift(this.generateStubMessage());
      }
    });

  }

  generateStubMessage() {
    const msg = new Message();
    msg.name = 'Sistema';
    msg.date = new Date().getTime();
    msg.content = 'Por favor aguarde... brevemente o Médico irá entrar em sessão.';
    msg.type = 0;
    msg.ownerId = 'system';
    return msg;
  }

  getFiles() {
    this.db.getFilesFromPatientClient(this.session.pin).then((result: []) => {
      this.files = result;
    });
  }

  downloadFile(file) {
    this.db.getFileFromPatientClient(file.name, this.session.pin).then((result: any) => {
      const fileUrl = result;
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      // const fileExt = result.type.split('/.')[1] != undefined ? result.type.split('/.')[1] : result.type.split('/')[1];
      a.href = fileUrl;
      // a.download = new Date().getTime() + '.' + 'png';
      document.body.appendChild(a);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    });
  }

  isHostInSession() {
    return this.session !== undefined && this.session.host;
  }

  hasSessionStarted() {
    return this.session !== undefined && this.session.host && this.session.visitor;
  }

  setupSession() {
    this.uid = Math.floor(Math.random() * 100);
  }

  uploadFile() {
    const result = this.db.uploadFileForPatient(this.session.id, this.filename);
    this.enableSpinner();
    if (this.fileDescription === undefined || this.fileDescription.trim() === '') {
      this.fileDescription = this.filename.name;
    }
    result[2].then(uploadResult => {
      this.db.updateFileDescription(result[1], this.fileDescription).toPromise().then(uploadFDresult => {
        this.disableSpinner();
        this.fileDescription = undefined;
        this.filename = undefined;
      });
    });
  }

  onChangeFileToUpload(event) {
    this.filename = event.target.files[0];
  }

  sendMessage() {
    this.db.addMessageClient(this.session.id, this.session.pin, Message.prepareMessageToSend('0', 0, this.message, 'Paciente')).then(result => {
      this.message = '';
      this.fetchMessages();
    });
  }

  start(withVideo = true) {
    this.hasStarted = true;
    if (this.session.state === 2) {
      return;
    }
    this.db.updateSessionClient(this.session);
    this.client = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'h264' });
    this.assignClientHandlers();

    this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: true, video: withVideo, screen: false });
    this.assignLocalStreamHandlers();
    // Join and publish methods added in this step
    this.initLocalStream(() => this.join(uid => this.publish(), error => {
      console.error(error);
      alert('Ocorreu um erro quando tentavamos iniciar a teleconsulta. Por favor atualize a página (F5) e tente novamente.');
    }));
  }

  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    // this.client.join(null, 'foo-bar', this.uid, onSuccess, onFailure);

    this.client.join(null, this.session.hash, this.uid, onSuccess, onFailure);
  }

  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => {
      console.log('Publish local stream error: ' + err);
      alert('Ocorreu um erro quando tentavamos iniciar o vídeo/aúdio da teleconsulta. Por favor atualize a página (F5) e tente novamente.');
    });
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {

    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => {
        console.error('getUserMedia failed', err);
        if (this.initRetries > 0) {
          alert('Não é possível iniciar a sessão vídeo e áudio. Por favor tente outro navegador/browser ou através de outro dispositivo.');
          this.videoWarning = 'Sem acesso ao vídeo ou áudio.';
        }
        alert('Ocorreu um erro ao tentar iniciar a camera. O sistema irá arrancar a chamada apenas com áudio.');
        this.initRetries += 1;
        this.hasStarted = false;
        this.videoWarning = 'Sem acesso a vídeo. Apenas áudio';
        this.start(false);
      }
    );
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }


}
