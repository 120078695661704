import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User as FirebaseUser } from 'firebase';
import { Role } from '../models/role.model';
import { User } from '../models/user.model';
import { DatabaseService } from './database.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User;

  constructor(public afAuth: AngularFireAuth, public router: Router, private db: DatabaseService) {
    const authSub = this.afAuth.authState.subscribe(user => {
      const localUser = AuthService.getUser();
      if (user && localUser && localUser.id === user.uid) {
        this.user = localUser;
        user.getIdToken().then(idToken => {
          this.db.getUser(user.uid).toPromise().then(getUserResult => {
            const mUser = new User(getUserResult);
            this.user = mUser;

            if (mUser.active) {
              this.user.token = idToken;
              localStorage.setItem('user', JSON.stringify(this.user));
            } else {
              localStorage.setItem('user', null);
              this.logout(false);
            }
          });
        });
      } else {
        localStorage.setItem('user', null);
        // this.logout(false);
        authSub.unsubscribe();
      }
    });
  }

  static getUser(): User {
    return User.importFromLocalStorage(JSON.parse(localStorage.getItem('user')));
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(result => {
        if (result === undefined || result.user === undefined || result.user.uid === undefined) {
          resolve(0);
          return;
        }
        this.db.getUser(result.user.uid).toPromise().then(getUserResult => {
          const mUser = new User(getUserResult);
          this.user = mUser;
          if (mUser.active) {
            localStorage.setItem('user', JSON.stringify(this.user));
            resolve(2);
          } else {
            this.afAuth.auth.signOut().then(_ => {
              resolve(1);
            });

          }
        });

      }, error => {
        reject(error);
      });
    });

    // .then(result => {
    //   console.log(result);
    //   //this.router.navigate(['sessions']);
    // });

  }

  register(email: string, password: string, name: string, role: Role) {
    // const result = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    // this.sendEmailVerification();
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then((result: any) => {
        if (result.user !== undefined && result.user.uid !== undefined) {
          const user = new User();
          user.id = result.user.uid;
          user.name = name;
          user.email = email;
          user.role = [role.code];
          this.db.addUser(user).then(addResult => {
            this.afAuth.auth.signOut().then(_ => {
              resolve(true);
            });

          }, error => {
          });
        } else {
          resolve(false);
        }
      }, error => {
        reject(error);
      });
    });
  }


  async logout(redirect = true) {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    if (redirect) {
      this.router.navigate(['login']);
    }

  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }


  get isDoctor(): boolean {
    return this.user !== undefined && this.user.role.indexOf('doctor') !== -1;
  }
}
