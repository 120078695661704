<div style="margin: 0 auto;height:200px;width:509px;">
  <img src="../../assets/images/logo_clinica.jpg" alt="machadolemos" height=200>
</div>
<div class="card" style="width: 31.5em;margin:0 auto; margin-top: 0px;">
  <div class="card-body">
    <p class="h4" style="text-align: center;">Consola de Administração</p>
    <form style="margin-top:20px;">
      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input [(ngModel)]="emailField" type="email" class="form-control" id="inputEmail"
          placeholder="Introduza aqui o seu e-mail" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="form-group">
        <label for="inputPassword">Palavra-passe</label>
        <input [(ngModel)]="passwordField" type="password" class="form-control" id="inputPassword"
          placeholder="Introduza aqui a sua palavra-passe" [ngModelOptions]="{standalone: true}">
      </div>
      <div>
        <button type="submit" (click)="login()" class="btn btn-primary" style="float:left;">Aceder</button>
        <a href="/register">
          <div class="btn btn-success" style="float:right;">Registar</div>
        </a>
      </div>

    </form>
  </div>
</div>
<a href="/home"><button style="position: fixed; right:0; top:0;" class="btn btn-info btn-sm">Consultas</button></a>
<div class="loading-screen" *ngIf="isLoading">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="text">
    A carregar... por favor aguarde..
  </div>
</div>