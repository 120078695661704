import * as moment from 'moment';

export class Utils {

  static getTime(date: Date): string {
    const momentDate = moment(date);
    return momentDate.format('HH:mm');
  }

  static getDate(date: Date): string {
    const momentDate = moment(date);
    return momentDate.format('DD/MM/YYYY');
  }

  static getAgeFromDate(date: Date): number {
    return moment().diff(moment(date), 'years', false);
  }

  static isDateExpired(date: Date): boolean {
    const momentDate = moment(date);
    return moment().isAfter(momentDate);
  }


  static getSessionStates(): string[] {
    return ['Aguardar pagamento', 'Pago', 'Sessão Terminada'];
  }

  static randomPinNumber(): number {
    return Math.floor(100000000 + Math.random() * 900000000);
  }

  static saveJsonToFile(data) {
    const theJSON = JSON.stringify(data);
    const uri = 'data:application/json;charset=UTF-8,' + encodeURIComponent(theJSON);

    const a = document.createElement('a');
    a.href = uri;
    a.innerHTML = 'Right-click and choose \'save as...\'';
    document.body.appendChild(a);
  }

  static validateNumber(nr: any) {
    return !isNaN(nr);
  }

  static validatePassword(password): boolean {
    return password !== undefined && password.trim().length >= 6;
  }

  static validateEmail(email): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static validateVatNumber(nr: number): boolean {
    const str = String(nr);
    let nif = str.toUpperCase();
    if (!/(PT)?([123568]\d{1}|45|7[0124579]|9[0189])(\d{7})/.test(nif)) {
      return false;
    }

    nif = nif.replace(/PT/, '');
    const checkDigit = (): number => {
      let c = 0;
      for (let i = 0; i < nif.length - 1; ++i) {
        c += Number(nif[i]) * (10 - i - 1);
      }
      c = 11 - (c % 11);
      return c >= 10 ? 0 : c;
    };

    return checkDigit() === Number(str.charAt(str.length - 1));
  }
}
