import { Component, OnInit, HostListener } from '@angular/core';
import { AgoraClient, NgxAgoraService, ClientEvent, StreamEvent, Stream } from 'ngx-agora';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/utils/database.service';
import { Session } from 'src/app/models/session.model';
import { Utils } from 'src/app/utils/utils';
import { BaseComponent } from 'src/app/utils/base/base.component';
import { Message } from 'src/app/models/message.model';

@Component({
  selector: 'app-host-session',
  templateUrl: './host-session.component.html',
  styleUrls: ['./host-session.component.scss']
})
export class HostSessionComponent extends BaseComponent implements OnInit {



  title = 'angular-video';
  localCallId = 'agora_local';
  videoWarning = 'A carregar vídeo... Por favor aguarde...';

  remoteCalls: string[] = [];

  messages: Message[] = [];
  message: string;

  session: Session;
  todaySessionId: string;
  mode = 0;

  private client: AgoraClient;
  private localStream: Stream;
  private uid: number;


  sessionHash: string;
  validSession = false;
  sessions = [];

  filename: string;
  fileDescription: string;
  files = [];

  hasStarted = false;

  initRetries = 0;

  constructor(private ngxAgoraService: NgxAgoraService, private activatedRoute: ActivatedRoute, private db: DatabaseService, router: Router) {
    super(router);
    // this.uid = Math.floor(Math.random() * 100);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const hash = params.hash;
      if (hash !== undefined) {
        this.sessionHash = hash;
        this.fetchInformationAboutSession();
      } else {
        alert('Sessão inválida');
        this.navigateToPage('sessions');
      }
    });
  }

  fetchInformationAboutSession() {
    this.db.getSessionByHash(this.sessionHash).then(snapshot => {
      if (snapshot.docs.length > 0) {
        const id = snapshot.docs[0].id;
        this.todaySessionId = id;
        this.validSession = true;

        this.db.getMessages(this.todaySessionId).subscribe(msgs => {
          this.messages = msgs.map(e => {
            const msg = new Message(e);
            return msg;
          });
        });

        this.db.getSessionById(id).subscribe(data => {
          this.session = new Session(data.payload);
          this.sessions.push(this.session);

          if (this.session.visitor === true) {
            this.videoWarning = 'A carregar vídeo... Por favor aguarde...';
            this.start();
          } else {
            this.videoWarning = 'A aguardar pelo convidado';
          }

          this.getFiles();

        });
      }
    });
  }

  getFiles() {
    this.db.getFilesFromPatient(this.session.id).listAll().then(result => {
      this.files = [];
      result.items.map(item => {
        const fileItem = {
          name: item.name,
          url: '',
          metadata: {},
        };
        item.getDownloadURL().then(url => {
          fileItem.url = url;
        });
        item.getMetadata().then(metadata => {
          fileItem.metadata = metadata;
        });
        this.files.push(fileItem);
      });
    });
  }

  uploadFile() {
    const result = this.db.uploadFileForPatient(this.session.id, this.filename);
    this.enableSpinner();
    result[2].then(uploadResult => {
      this.db.updateFileDescription(result[1], this.fileDescription).toPromise().then(uploadFDresult => {
        this.disableSpinner();
        this.fileDescription = undefined;
        this.filename = undefined;
        this.getFiles();
      });
    });
  }

  onChangeFileToUpload(event) {
    this.filename = event.target.files[0];
  }

  onChangedSession(event) {
    this.session = this.sessions.find(s => s.id === event);
  }

  updateSession() {
    this.db.updateSession(this.session);
  }

  closeSession() {
    this.session.state = 2;
    this.session.host = false;
    this.client.leave();
    this.localStream.stop();
    this.localStream.close();
    this.db.updateSession(this.session);
  }

  exitSession() {
    this.navigateToPage('sessions');
  }

  isHostInSession() {
    return this.session !== undefined && this.session.host;
  }

  hasSessionStarted() {
    return this.session !== undefined && this.session.host && this.session.visitor;
  }

  setupSession() {
    this.uid = Math.floor(Math.random() * 100);
  }

  sendMessage() {
    const session = this.sessions.find(s => s.id = this.todaySessionId);
    this.db.addMessage(this.todaySessionId, Message.prepareMessageToSend(session.userId, 0, this.message, 'Médico')).then(result => {
      this.message = '';
    });
  }

  start(withVideo = true) {
    if (this.hasStarted) {
      return;
    }

    this.hasStarted = true;
    this.session.host = true;
    this.db.updateSession(this.session);
    this.client = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'h264' });
    this.assignClientHandlers();

    this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: true, video: withVideo, screen: false });
    this.assignLocalStreamHandlers();
    // Join and publish methods added in this step
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.log(error)));
  }

  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    // this.client.join(null, 'foo-bar', this.uid, onSuccess, onFailure);

    this.client.join(null, this.session.hash, this.uid, onSuccess, onFailure);
  }

  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => {
        if (this.initRetries > 0) {
          alert('Não é possível iniciar a sessão vídeo e áudio. Por favor tente outro navegador/browser ou através de outro dispositivo.');
          this.videoWarning = 'Sem acesso ao vídeo ou áudio.';
        }
        alert('Ocorreu um erro ao tentar iniciar a camera. O sistema irá arrancar a chamada apenas com áudio.');
        this.initRetries += 1;
        this.hasStarted = false;
        this.videoWarning = 'Sem acesso a vídeo. Apenas áudio';
        this.start(false);
      }
    );
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }


}
