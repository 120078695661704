// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  agora: {
    appId: 'ff646efa9e89458ab2c72ca308b846d0'
  },
  firebase: {
    apiKey: 'AIzaSyDmWH8xD-WBRHGGXpwED6Il-JbZNSdw6Qk',
    authDomain: 'heartexternal-f0ea9.firebaseapp.com',
    databaseURL: 'https://heartexternal-f0ea9.firebaseio.com',
    projectId: 'heartexternal-f0ea9',
    storageBucket: 'heartexternal-f0ea9.appspot.com',
    messagingSenderId: '866194672419',
    appId: '1:866194672419:web:64ea3a15bc55221ad39a96'
  },
  server: {
    host: 'https://demo.machadolemos.com'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
