import { Component, OnInit } from '@angular/core';
import { AuthService } from '../utils/auth.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../utils/base/base.component';
import { Utils } from '../utils/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  emailField: string;
  passwordField: string;

  constructor(private auth: AuthService, router: Router) { super(router); }


  validateFields(): boolean {

    if (this.emailField === undefined || this.emailField.length === 0 || !Utils.validateEmail(this.emailField)) {
      alert('Por favor introduza um e-mail válido. Obrigado');
      return false;
    }

    if (this.passwordField === undefined || this.passwordField.length < 6) {
      alert('A palavra-passe introduzida não é válida. Tente novamente');
      return false;
    }


    return true;
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn) {
      this.router.navigate(['sessions']);
    }
  }


  login() {
    if (!this.validateFields()) {
      return;
    }

    this.enableSpinner();
    this.auth.login(this.emailField, this.passwordField).then(result => {
      this.disableSpinner();
      if (result === 0) {
        alert('Email/Palavra-passe erradas. Por favor tente novamente');
      } else if (result === 1) {
        alert('Conta inactiva. Por favor contacte através do e-mail: consultas@machadolemos.com e peça ativação da conta');
      } else if (result === 2) {
        this.navigateToPage('sessions');
      }

    }, error => {
      this.disableSpinner();
      alert('Email/Palavra-passe erradas. Por favor tente novamente');
    });

  }

  // register() {
  //   if (!this.validateFields()) {
  //     return;
  //   }


  //   this.auth.register(this.emailField, this.passwordField);
  // }




}
