import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Session } from '../models/session.model';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { SettableMetadata } from '@angular/fire/storage/interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private firestore: AngularFirestore, private afStorage: AngularFireStorage,
    private realtime: AngularFireDatabase, private httpClient: HttpClient) { }


  downloadFirestoreCollection(name) {
    return this.firestore.collection(name).get().toPromise();
  }

  // SESSION
  getSessions() {
    return this.firestore
      .collection('sessions', ref => ref.where('userId', '==', this.getUserFromLocal().id)
        .orderBy('scheduledDate', 'desc')).snapshotChanges();
  }

  getSessionsByPatientId(patientId: string) {
    return this.firestore.collection('sessions').ref.where('patientId', '==', patientId).get();
  }

  getSessionByHash(hash: string) {
    return this.firestore.collection('sessions').ref.where('hash', '==', hash).get();
  }

  getSessionByHashClient(hash: string) {
    return this.GET('session?hash=' + hash);
  }

  getSessionById(sessionId: string) {
    return this.firestore.collection('sessions').doc(sessionId).snapshotChanges();
  }

  getSessionsByUserAndDate(userId: string, date: Date) {
    const startdate = new Date(date);
    const endDate = new Date(date);
    startdate.setUTCMinutes(date.getUTCMinutes() - 1);
    endDate.setUTCMinutes(date.getUTCMinutes() + 1);

    // return this.firestore.collection('sessions').ref.where('userId', '==', userId).where('scheduledDate', '==', date).get();
    const ref = this.firestore.collection('sessions').ref;
    return ref.where('userId', '==', userId).where('scheduledDate', '>', startdate).where('scheduledDate', '<', endDate).get();
  }

  updateSession(session) {
    return this.firestore.collection('sessions').doc(session.id).update(session.export());
  }

  updateSessionClient(session) {
    this.PUT('session?id=' + session.id, session.export());
  }

  updateSessionState(session, state) {
    return this.firestore.collection('sessions').doc(session.id).update({ state });
  }

  addSession(session) {
    return this.firestore.collection('sessions').add(session.export());
  }


  // getPatient(patientId: string) {
  //   // return this.firestore.collection('patients/').doc(patientId).snapshotChanges();
  //   return this.GET('patient?id=' + patientId);
  // }

  // getPatientByVatNumber(vatNumber: number) {
  //   // return this.firestore.collection('patients').ref.where('vatNumber', '==', vatNumber).get();
  //   return this.GET('patient?vn=' + vatNumber);
  // }

  // getPatients() {
  //   // return this.firestore.collection('patients').snapshotChanges();
  //   return this.GET('patients');
  // }

  // // deletePatient(patientId: string) {
  // //   return this.firestore.collection('patients/').doc(patientId).delete();
  // // }

  // updatePatient(patient) {
  //   // return this.firestore.collection('patients').doc(patient.id).update(patient.export());
  //   return this.PUT('patient', patient);
  // }

  // addPatient(patient) {
  //   // return this.firestore.collection('patients').add(patient.export());
  //   return this.POST('patient', patient);
  // }


  getUser(userId: string) {
    return this.firestore.collection('users/').doc(userId).get();
  }

  getUsers() {
    return this.firestore.collection('users').snapshotChanges();
  }

  getUsersByRole(role: string) {
    return this.firestore.collection('users').ref.where('role', 'array-contains', role).get();
  }

  addUser(user: User) {
    return this.firestore.collection('users').doc(user.id).set(user.export());
  }

  updateUser(user: User) {
    return this.firestore.collection('users').doc(user.id).update(user.export());
  }

  getFilesFromPatient(sessionId: string) {
    const path = `patientFiles/${sessionId}/`;
    const ref = this.afStorage.storage.ref(path);

    return ref;
  }

  getFilesFromPatientClient(pin: number) {
    return this.GET('files?id=' + pin);
  }

  getFileFromPatientClient(fileName: string, pin: number) {
    return this.GET('file?id=' + pin + '&fn=' + fileName);
  }

  uploadFileForPatient(sessionId: string, file: string): [string, string, AngularFireUploadTask] {
    const randomId = Math.random().toString(36).substring(2);
    const path = `patientFiles/${sessionId}/${Date.now()}_${randomId}`;
    const ref = this.afStorage.ref(path);
    const task = ref.put(file);

    return [randomId, path, task];
  }

  updateFileDescription(path, fileDescription) {
    const metadata: SettableMetadata = {
      customMetadata: {
        description: fileDescription
      }
    };
    return this.afStorage.ref(path).updateMetadata(metadata);
  }


  getMessages(sessionId) {
    // return this.firestore.collection('messages/').snapshotChanges();
    return this.realtime.list(`messages/${sessionId}`).valueChanges();
  }

  addMessage(sessionId, message) {
    // return this.firestore.collection(`messages/${sessionId}`).add(message);
    return this.realtime.list(`messages/${sessionId}`).push(message.export());
  }

  getMessagesClient(sessionId, pinNumber) {
    // return this.firestore.collection('messages/').snapshotChanges();
    // return this.realtime.list(`messages/${sessionId}`).valueChanges();
    return this.GET('messages?id=' + sessionId + '&pin=' + pinNumber);
  }

  addMessageClient(sessionId, pinNumber, message) {
    // return this.firestore.collection(`messages/${sessionId}`).add(message);
    // return this.realtime.list(`messages/${sessionId}`).push(message.export());
    return this.POST('message?id=' + sessionId + '&pin=' + pinNumber, message);
  }


  sendMarketingMessage(destination: number, text: string) {
    return this.POSTSMS('marketing', { destination, text });
  }

  sendMarketingMultiMessage(destinations: [number], text: string, date?: number, expiry?: number) {
    const body: any = { destinations, text };
    if (date && expiry) {
      body.scheduled = {
        date,
        expiry
      };
    }
    return this.POSTSMS('marketing_multi', body);
  }



  private getUserFromLocal(): User {
    const json = localStorage.getItem('user');
    return json !== null ? User.importFromLocalStorage(JSON.parse(json)) : undefined;
  }

  get OPTIONS() {
    const user = this.getUserFromLocal();
    if (user !== undefined) {
      const userToken = user !== undefined ? user.token : '';
      const headers = new HttpHeaders().set('x-a-t-ml', userToken);
      return { headers };
    }
    return {};
  }

  private GET(resource) {
    return this.httpClient.get(environment.server.host + '/api/' + resource, this.OPTIONS).toPromise();
  }

  private POST(resource, data) {
    return this.httpClient.post(environment.server.host + '/api/' + resource, data, this.OPTIONS).toPromise();
  }

  private PUT(resource, data) {
    return this.httpClient.put(environment.server.host + '/api/' + resource, data, this.OPTIONS).toPromise();
  }

  private POSTSMS(resource, data) {
    return this.httpClient.post(environment.server.host + '/sms/' + resource, data, this.OPTIONS).toPromise();
  }

}
