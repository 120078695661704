import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../utils/base/base.component';
import { Router } from '@angular/router';
import { DatabaseService } from '../utils/database.service';
import { Utils } from '../utils/utils';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent extends BaseComponent implements OnInit {

  message: string;
  destination: number;

  destinations: number[] = [];

  tab = 1;

  scheduleDate: Date;
  enableScheduling = false;

  constructor(router: Router, private db: DatabaseService) { super(router); }

  ngOnInit(): void {
  }

  onChangeSchedulingDate(event) {
    console.log(event);
  }

  onCheckboxChange(event) {
    console.log("checkbox", event);
  }

  removePhoneNumber(index) {
    this.destinations.splice(index, 1);
  }

  removeAllPhoneNumbers() {
    this.destinations = [];
  }

  addPhoneNumber() {
    if (!this.validateNumber()) {
      return;
    }

    if (this.destinations.indexOf(this.destination) !== -1) {
      alert('Número destinatário já foi adicionado à lista');
      return;
    }

    this.destinations.push(this.destination);
    this.destination = undefined;
  }

  addMultiplePhoneNumbers() {
    const r = prompt('Por favor introduza os números separados por ponto e vírgula', '');

    if (r == null || r === '') {
      alert('É obrigatório colocar os números de telemóvel separados por ponto e vírgula (;)');
      return;
    } else {
      const arrayOfPN = r.split(';');
      const repetitions = [];
      const invalidNumbers = [];
      const validNumbersToAdd = [];

      if (arrayOfPN.length > 0) {
        for (const it of arrayOfPN) {
          if (!Utils.validateNumber(it) || String(it).length !== 9 || !String(it).startsWith('9')) {
            invalidNumbers.push(it);
          } else {
            const nr = Number(it);
            if (this.destinations.indexOf(nr) !== -1 || validNumbersToAdd.indexOf(nr) !== -1) {
              repetitions.push(nr);
            } else {
              validNumbersToAdd.push(nr);
            }
          }
        }
        if (validNumbersToAdd.length > 0) {
          validNumbersToAdd.forEach(element => {
            this.destinations.push(element);
          });
        }
        alert('Estatísticas:\nVálidos: ' + validNumbersToAdd.length + '\nInválidos: ' + invalidNumbers.length + '\nRepetidos: ' + repetitions.length);
        if (invalidNumbers.length > 0) {
          alert('Inválidos: ' + invalidNumbers.toString());
        }

        if (repetitions.length > 0) {
          alert('Repetidos: ' + repetitions.toString());
        }
      } else {
        alert('Não foi encontrado um formato válido de múltiplos números.');
      }

    }

  }

  validateNumber(): boolean {
    if (this.destination === undefined || String(this.destination).length !== 9 || !String(this.destination).startsWith('9')) {
      alert('Número destinatário inválido. Só é possível utilizar os seguintes números: 96... 93... 91... 92...');
      return false;
    }
    return true;
  }

  validateMessageToSend(): boolean {

    if (this.message === undefined || this.message.trim().length === 0) {
      alert('Mensagem tem de estar preenchida com conteúdo.');
      return false;
    }

    return this.validateNumber();
  }

  sendSingleMessage() {
    if (!this.validateMessageToSend()) {
      return;
    }
    this.db.sendMarketingMessage(this.destination, this.message);

  }

  sendMultipleMessages() {

  }


  sendMessage() {
    if (this.tab === 0) {
      this.sendMessage();
    } else if (this.tab === 1) {
      this.sendMultipleMessages();
    }

  }




}
