<div class="card">
  <div class="card-header">
    <div style="float: left;">
      Marketing - Envio de SMS
    </div>
    <div style="float: right;">
      <a href="/sessions"><button class="btn btn-secondary" style="margin-left: 5px;"> Voltar a
          sessões</button></a>
    </div>
    <div style="clear: both"></div>
  </div>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: tab == 0}" (click)="tab = 0">Simples</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [ngClass]="{active: tab == 1}" (click)="tab = 1">Multiplas</a>
      </li>
    </ul>
  </div>
  <div *ngIf="tab == 0" class="card-body">
    <div class="form-group">
      <label for="inputDestination">Telemóvel</label>
      <input [(ngModel)]="destination" type="number" class="form-control" id="inputDestination"
        placeholder="Coloque aqui o número (português) a enviar" [ngModelOptions]="{standalone: true}" />
    </div>
    <div class="form-group">
      <label for="inputMessage">Mensagem a enviar:</label>
      <input [(ngModel)]="message" type="text" class="form-control" id="inputMessage"
        placeholder="Coloque a mensagem a enviar" [ngModelOptions]="{standalone: true}" />
    </div>
    <div>
      <button type="submit" (click)="sendMessage()" class="btn btn-success" style="float:right;">Enviar</button>
    </div>
  </div>
  <div *ngIf="tab == 1" class="card-body">
    <div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="inputDestination">Telemóvel (<span
                style="cursor: pointer; text-decoration: underline; color: steelblue;"
                (click)="addMultiplePhoneNumbers()">Adicionar múltiplos números? Clique
                aqui</span>)</label>
            <div class="input-group">
              <input [(ngModel)]="destination" type="number" class="form-control" id="inputDestination"
                placeholder="Coloque aqui o número (português) a enviar" [ngModelOptions]="{standalone: true}" />
              <span class="input-group-btn">
                <button class="btn btn-primary" (click)="addPhoneNumber()" type="submit">
                  Adicionar
                </button>
              </span>
            </div>
          </div>
          <div style="min-height: 54px;">
            <div class="form-group" *ngIf="enableScheduling" style="float:left;">
              <input [ngModel]="scheduleDate | date:'yyyy-MM-dd'" (ngModelChange)="onChangeSchedulingDate($event)"
                type="date" class="form-control" id="inputScheduling" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-group form-check" style="float:right">
              <input type="checkbox" class="form-check-input" id="inputSchedulingActivation"
                (change)="enableScheduling = $event.target.checked" />
              <label class="form-check-label" for="inputSchedulingActivation">Ativar agendamento de SMS</label>
            </div>
            <div style="clear:both;"></div>
          </div>
          <div class="form-group">
            <label for="inputMessage">Mensagem a enviar:</label>
            <input [(ngModel)]="message" type="text" class="form-control" id="inputMessage"
              placeholder="Coloque a mensagem a enviar" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
        <div class="col-sm-6">
          <span *ngFor="let dest of destinations" class="badge badge-pill badge-success"
            style="margin-left: 5px;">{{dest}}</span>
        </div>
      </div>
    </div>
    <div>
      <button type="submit" (click)="sendMessage()" class="btn btn-success" style="float:right;">Enviar</button>
    </div>
  </div>
  <div>

  </div>
</div>
<div class="loading-screen" *ngIf="isLoading">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="text">
    A carregar... por favor aguarde..
  </div>
</div>