import { Utils } from '../utils/utils';

export class Session {
  id: string;
  hash: string;
  pin: number;
  creationDate: Date;
  scheduledDate: Date;
  userId: string;
  visitor: boolean;
  host: boolean;
  state: number;


  motive: string;
  notes: string;

  /**
   * Session Constructor
   * Accepts no arguments or a Firestore Payload
   * @param firestoreObjectDoc This is the firestore paylod (data.payload)
   */
  constructor(firestoreObjectDoc?) {

    if (firestoreObjectDoc !== undefined) {
      this.id = firestoreObjectDoc.id;
      this.creationDate = new Date(firestoreObjectDoc.data().creationDate.toDate());
      this.scheduledDate = new Date(firestoreObjectDoc.data().scheduledDate.toDate());
      this.hash = firestoreObjectDoc.data().hash;
      this.userId = firestoreObjectDoc.data().userId;
      this.visitor = firestoreObjectDoc.data().visitor;
      this.host = firestoreObjectDoc.data().host;
      this.state = firestoreObjectDoc.data().state;
      this.motive = firestoreObjectDoc.data().motive;

      this.notes = firestoreObjectDoc.data().notes ?? '';

      this.pin = firestoreObjectDoc.data().pin;

    } else {
      this.creationDate = new Date();
      this.scheduledDate = new Date();
      this.scheduledDate.setHours(9, 0, 0);
      this.state = 1;
    }
  }

  static initClientSession(data): Session {
    const s = new Session();
    s.id = data.id;
    s.creationDate = new Date(data.creationDate);
    s.scheduledDate = new Date(data.scheduledDate);
    s.hash = data.hash;
    s.userId = data.userId;
    s.visitor = data.visitor;
    s.host = data.host;
    s.state = data.state;
    s.motive = data.motive;


    s.notes = data.notes ?? '';

    s.pin = data.pin;

    return s;
  }

  getScheduledDate(): string {
    return Utils.getDate(this.scheduledDate);
  }

  getScheduledTime(): string {
    return Utils.getTime(this.scheduledDate);
  }

  generatePin(): number {
    this.pin = Utils.randomPinNumber();
    return this.pin;
  }

  generateHash(): string {
    this.hash = '' + this.pin;
    return this.hash;
  }



  export(): object {
    const obj = Object.assign({}, this);
    Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});
    delete obj.id;

    return obj;
  }
}
