import { Component, OnInit } from '@angular/core';
import { Session } from 'src/app/models/session.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/utils/database.service';
import { Utils } from 'src/app/utils/utils';
import { User } from 'src/app/models/user.model';
import { BaseComponent } from 'src/app/utils/base/base.component';
import { Message } from 'src/app/models/message.model';
import { AuthService } from 'src/app/utils/auth.service';

@Component({
  selector: 'app-add-session',
  templateUrl: './add-session.component.html',
  styleUrls: ['./add-session.component.scss']
})
export class AddSessionComponent extends BaseComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private db: DatabaseService,
    router: Router, private auth: AuthService) { super(router); }

  // tslint:disable-next-line:max-line-length
  hours: string[] = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30'];
  selectedHoursIndex = 0;
  selectedUserIndex = 0;
  session: Session;
  editMode = false;
  // users: User[] = [];


  mode = 0;
  files = [];
  messages: Message[] = [];

  isDoctor() {
    return this.auth.isDoctor;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      if (id !== undefined) {
        this.enableSpinner();
        this.db.getSessionById(id).subscribe(data => {
          if (data.payload.exists) {
            this.session = new Session(data.payload);

            this.selectedHoursIndex = this.hours.indexOf(Utils.getTime(this.session.scheduledDate));

            this.disableSpinner();

            if (this.session.state > 1 && this.isDoctor()) {
              this.getFiles();
              this.getMessages();
            }

            // this.populateUsers(this.session.userId);
            this.editMode = true;
          } else {
            alert('Não existe nenhuma sessão com esse identificador');
          }
        });
      } else {
        this.session = new Session();
        this.session.userId = AuthService.getUser().id;
        // this.populateUsers();
      }
    });

  }

  getFiles() {
    this.db.getFilesFromPatient(this.session.id).listAll().then(result => {
      this.files = [];
      result.items.map(item => {
        const fileItem = {
          name: item.name,
          url: '',
          metadata: {},
        };
        item.getDownloadURL().then(url => {
          fileItem.url = url;
        });
        item.getMetadata().then(metadata => {
          fileItem.metadata = metadata;
        });
        this.files.push(fileItem);
      });
    });
  }

  getMessages() {
    this.db.getMessages(this.session.id).subscribe(msgs => {
      this.messages = msgs.map(e => {
        const msg = new Message(e);
        return msg;
      });
    });
  }

  // populateUsers(userId?) {
  //   this.enableSpinner();
  //   this.db.getUsersByRole('doctor').then(data => {
  //     this.users = data.docs.map(e => {
  //       const user = new User(e);
  //       return user;
  //     });
  //     this.session.userId = userId ? userId : this.users[0].id;
  //     this.selectedUserIndex = this.users.map(u => u.id).indexOf(this.session.userId);
  //     this.disableSpinner();
  //   });
  // }

  onScheduledTimeChange(indexValue) {
    const value = this.hours[indexValue];
    const hours = +value.split(':')[0];
    const minutes = +value.split(':')[1];
    this.session.scheduledDate.setHours(hours, minutes, 0);
  }

  onScheduledDateChange(event) {
    const date = new Date(event);
    this.session.scheduledDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  }

  onSelectedUserChange(indexValue) {

    // this.session.userId = this.users[indexValue].id;
  }

  onSelectedStateChange(indexValue) {
    this.session.state = indexValue;
  }

  // fetchPatient() {
  //   if (!Utils.validateVatNumber(this.patientVatNumber)) {
  //     alert('O número de contribuinte que colocou não é válido. Tente novamente.');
  //     return;
  //   }
  //   this.enableSpinner();
  //   this.db.getPatientByVatNumber(this.patientVatNumber).then(snapshot => {
  //     this.disableSpinner();
  //     if (snapshot !== undefined && snapshot.hasOwnProperty('id')) {
  //       this.session.patient = new Patient(snapshot);
  //       this.session.patientId = this.session.patient.id;
  //     } else {
  //       const msg = 'Não existe nenhum paciente com o nº de contribuinte ' + this.patientVatNumber
  //         + '. No lado direito do ecrã poderá criar um.';
  //       alert(msg);
  //       this.session.patient = new Patient();
  //       this.session.patient.vatNumber = this.patientVatNumber;
  //     }
  //   });
  // }

  getSessionStates() {
    return Utils.getSessionStates();
  }

  isSessionSubmissionValid(): boolean {

    // if (this.session.userId === undefined) {
    //   alert('Por favor selecione um responsável para a sessão');
    //   return false;
    // }

    // if (this.session.patientVat === undefined && Utils.validateVatNumber(this.session.patientVat)) {
    //   alert('Por favor indique o número de contribuinte válido do paciente');
    //   return false;
    // }

    if (this.session.motive === undefined) {
      alert('Por favor introduza um motivo para sessão');
      return false;
    }

    return true;
  }

  updateSession() {
    if (!this.isSessionSubmissionValid()) {
      return;
    }
    this.enableSpinner();
    this.db.getSessionsByUserAndDate(this.session.userId, this.session.scheduledDate).then(snapshot => {
      if (snapshot.docs.length > 0) {
        const existingSession = new Session(snapshot.docs[0]);
        if (this.session.id === existingSession.id && this.editMode) {
          this.db.updateSession(this.session).then(result => {
            this.disableSpinner();
            alert('Sessão atualizada');
            this.navigateToPage('/sessions');
          });
        } else {
          this.disableSpinner();
          const date = this.session.getScheduledDate() + ' às ' + this.session.getScheduledTime();
          alert('Já existe uma sessão para a data ' + date);
        }

      } else {
        if (this.editMode) {

          this.db.updateSession(this.session).then(result => {
            this.disableSpinner();
            alert('Sessão atualizada');
            this.navigateToPage('/sessions');
          });
        } else {
          const pin = this.session.generatePin();
          this.session.generateHash();
          this.db.addSession(this.session).then(result => {
            this.disableSpinner();
            alert('Sessão adicionada. O PIN da sessão é: ' + pin);
            this.navigateToPage('/sessions');
          });
        }
      }
    });
  }

}
