import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../utils/database.service';
import { Session } from '../models/session.model';
import { Utils } from '../utils/utils';
import { AuthService } from '../utils/auth.service';
import { BaseComponent } from '../utils/base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent extends BaseComponent implements OnInit {

  sessions: Session[] = [];
  patients = {};

  constructor(private db: DatabaseService, private auth: AuthService, router: Router) { super(router); }

  ngOnInit(): void {
    this.populateSessions();
  }

  isDoctor() {
    return this.auth.isDoctor;
  }


  populateSessions() {
    this.db.getSessions().subscribe(data => {
      this.sessions = data.map(e => {
        const session = new Session(e.payload.doc);
        return session;
      });
    });
  }

  getDate(date: Date): string {
    return Utils.getDate(date);
  }

  getTime(date: Date): string {
    return Utils.getTime(date);
  }

  getNumberOfFinishedSessions() {
    return this.sessions.filter(s => s.state >= 2).length;
  }

  getNumberOfActiveSessions() {
    return this.sessions.filter(s => s.state >= 0 && s.state < 2).length;
  }

  updateSessionState(session, state) {
    this.db.updateSessionState(session, state);
  }

  cancelSession(session) {
    this.updateSessionState(session, 3);
  }

  readySession(session) {
    this.updateSessionState(session, 1);
  }


  logout() {
    this.auth.logout();
    this.navigateToPage('home');
  }

  getSessionState(session) {
    switch (session.state) {
      case 3:
        return 'Sessão cancelada';
      case 2:
        return 'Sessão terminada';
      case 1:
        if (session.visitor) {
          return 'Convidado na sessão';
        } else {
          return 'Sessão disponível';
        }
      case 0:
      default:
        return 'Aguardar';
    }

  }


}
