<div class="card">
  <div class="card-header">
    <div style="float: left;">
      Sessões - Ativas: {{getNumberOfActiveSessions()}} | Finalizadas: {{getNumberOfFinishedSessions()}}
    </div>
    <div style="float: right;">
      <a href="/sessions/add-session"><button class="btn btn-secondary" style="margin-left: 5px;">Nova
          sessão</button></a>
      <button class="btn btn-danger" style="margin-left: 5px;" (click)="logout()">Sair</button>
    </div>
    <div style="clear: both"></div>
  </div>
  <div class="card-body">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Motivo</th>
          <th scope="col">Estado</th>
          <th scope="col">Acções</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let session of sessions">
          <th scope="row">{{getDate(session.scheduledDate)}} {{getTime(session.scheduledDate)}}</th>
          <td>{{session.motive}}</td>
          <td>
            {{getSessionState(session)}}
          </td>
          <td>
            <a href="/sessions/host-session/{{session.hash}}"><button *ngIf="isDoctor()"
                [disabled]="session.state == 0 || session.state == 2" class="btn btn-success">Entrar</button></a>
            <a href="/sessions/manage-session/{{session.id}}"><button class="btn btn-primary">
                {{ session.state >= 1 ? 'Ver' : 'Editar'}}</button></a>
            <button [disabled]="session.state > 1" class="btn btn-danger"
              (click)="cancelSession(session)">Cancelar</button>
            <button [disabled]="session.state <= 2" class="btn btn-warning" (click)="readySession(session)">Reiniciar
              sessão</button>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>