<div style="margin: 0 auto;height:100px;width:254px;">
  <img src="../../assets/images/logo_clinica.jpg" alt="machadolemos" height=100>
</div>
<div class="card" style="width: 31.5em;margin:0 auto; margin-top: 0px;">
  <div class="card-body">
    <p class="h4" style="text-align: center;">Registo de Conta (Administração)</p>
    <form autocomplete="new" style="margin-top:20px;">
      <div class="form-group">
        <label for="inputName">Nome</label>
        <input [(ngModel)]="nameField" type="name" class="form-control" id="inputName"
          placeholder="Introduza aqui o seu nome" [ngModelOptions]="{standalone: true}" readonly="readonly"
          onfocus="javascript: this.removeAttribute('readonly')">
      </div>
      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input [(ngModel)]="emailField" type="email" class="form-control" id="inputEmail"
          placeholder="Introduza aqui o seu e-mail" [ngModelOptions]="{standalone: true}" readonly="readonly"
          onfocus="javascript: this.removeAttribute('readonly')">
      </div>
      <div class="form-group">
        <label for="inputPassword">Palavra-passe</label>
        <input [(ngModel)]="passwordField" type="password" class="form-control" id="inputPassword"
          placeholder="Introduza aqui a sua palavra-passe" [ngModelOptions]="{standalone: true}" readonly="readonly"
          onfocus="javascript: this.removeAttribute('readonly')">
      </div>
      <div class="form-group">
        <label for="inputRole">Papel</label>
        <select class="form-control" [value]="selectedRoleIndex" id="inputRole"
          (change)="onSelectedRoleChange($event.target.value)">
          <option *ngFor="let role of roles; let i = index" value="{{i}}">{{role.name}}</option>
        </select>
      </div>
      <div>
        <button type="submit" (click)="register()" class="btn btn-primary" style="float:left;">Registar</button>
        <!-- <button type="submit" (click)="register()" class="btn btn-success" style="float:right;">Registar</button> -->
      </div>

    </form>
  </div>
</div>
<a href="/home"><button style="position: fixed; right:0; top:0;" class="btn btn-info btn-sm">Consultas</button></a>
<div class="loading-screen" *ngIf="isLoading">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="text">
    A carregar... por favor aguarde..
  </div>
</div>