import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../utils/base/base.component';
import { Utils } from '../utils/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  nifField: number;
  pinField: number;



  constructor(routerSuper: Router) { super(routerSuper); }

  ngOnInit(): void {
  }


  validateFields(): boolean {

    // if (this.nifField === undefined || !Utils.validateVatNumber(this.nifField)) {
    //   alert('Por favor introduza um NIF válido. Obrigado');
    //   return false;
    // }

    if (this.pinField === undefined || this.pinField.toString().length <= 4) {
      alert('Por favor introduza um PIN válido. Obrigado');
      return false;
    }


    return true;
  }

  doEnter() {
    if (!this.validateFields()) {
      return;
    }


    this.router.navigateByUrl('/sessions/setup/' + this.pinField);
  }

  doSchedule() {
    alert('Para marcar consultas por favor entre em contacto através dos números:\n252 618 016 ou 968 836 046\n\nOu através da página de facebook:\nwwww.facebook.com/drmachadolemos\n\nObrigado');
  }

}
