import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/utils/database.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from 'src/app/models/session.model';
import { BaseComponent } from 'src/app/utils/base/base.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent extends BaseComponent implements OnInit {

  step = 0;

  // // inputName: string;
  // // inputDob: Date;
  // // inputVatNumber: number;
  // // inputHealthNumber: number;
  // // inputEmail: string;

  hash: string;
  hashMessage = 'Por favor aguarde enquanto tentamos encontrar a sua sessão';

  session: Session;

  constructor(private db: DatabaseService, private activatedRoute: ActivatedRoute, router: Router) { super(router); }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const hash = params.hash;
      if (hash !== undefined) {
        this.hash = hash;
        this.fetchInformationAboutSession();
      } else {
        alert('Identificação de sessão inválida');
        this.navigateToPage('home');
      }
    });
  }

  fetchInformationAboutSession() {
    this.db.getSessionByHashClient(this.hash).then(data => {
      if (data !== undefined) {
        this.session = Session.initClientSession(data);
        if (this.session.state === 2) {
          this.step = 5;
        } else {
          this.moveToSessionPage();
        }
      } else {
        alert('Identificação de sessão inválida');
        this.navigateToPage('home');
      }
    });
  }

  // onCheckboxChange(checkboxLabel, event) {
  //   const checkedState = event.target.checked;
  //   if (checkboxLabel === 'terms') {
  //     this.session.patient.agreedTermsOfService = checkedState;
  //   } else if (checkboxLabel === 'gdpr') {
  //     this.session.patient.agreedGPDR = checkedState;
  //   }

  // }

  // handleCurrentStep(): boolean {
  //   switch (this.step) {
  //     case 0:
  //       if (this.session.patient.name.trim().length === 0) {
  //         alert('Por favor preencha o seu nome e clique em "Seguinte"');
  //         return false;
  //       }
  //       break;
  //     case 1:
  //       if (this.session.patient.healthNumber.toString().length !== 9) {
  //         alert('Por favor preencha um nº de utente válido e clique em "Seguinte"');
  //         return false;
  //       }
  //       break;
  //     case 2:
  //       if (this.session.patient.email.trim().length > 0 && !Utils.validateEmail(this.session.patient.email)) {
  //         alert('O e-mail colocado é inválido. Por favor verifique novamente.');
  //         return false;
  //       }
  //       break;
  //     case 3:
  //       if (!this.session.patient.agreedGPDR || !this.session.patient.agreedTermsOfService) {
  //         alert('Tem de aceitar os Termos de Serviço e o Regulamento de Proteção de dados antes de podermos dar inicio à consulta.');
  //         return false;
  //       }
  //       break;
  //     default:
  //       break;
  //   }

  //   return true;
  // }

  // moveToNextStep() {
  //   if (this.handleCurrentStep()) {
  //     if (this.session.state > 0 && this.step === 3) {
  //       this.moveToSessionPage();
  //       return;
  //     }
  //     this.step += 1;
  //   }
  // }

  moveToSessionPage() {
    this.enableSpinner();
    this.navigateToPage('sessions/session/' + this.hash);

  }

}
