import { Component, OnInit } from '@angular/core';
import { AuthService } from '../utils/auth.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../utils/base/base.component';
import { User } from '../models/user.model';
import { DatabaseService } from '../utils/database.service';
import { Utils } from '../utils/utils';
import { Role } from '../models/role.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit {

  emailField: string;
  passwordField: string;
  nameField: string;
  selectedRoleIndex = 0;
  roles: Role[] = [];

  constructor(private auth: AuthService, router: Router, private db: DatabaseService) { super(router); }

  ngOnInit(): void {
    this.roles = Role.getRoles();
    // this.emailField = Utils.randomPinNumber() + "mail@machadolemos.com";
    // this.nameField = "Teste";
    // this.passwordField = "12345678";
  }

  onSelectedRoleChange(indexValue) {
    this.selectedRoleIndex = indexValue;
  }

  validateFields(): boolean {

    if (this.nameField === undefined || this.nameField.length === 0) {
      alert('Por favor introduza um nome válido. Obrigado');
      return false;
    }

    if (this.emailField === undefined || this.emailField.length === 0 || !Utils.validateEmail(this.emailField)) {
      alert('Por favor introduza um e-mail válido. Obrigado');
      return false;
    }

    if (!Utils.validatePassword(this.passwordField)) {
      alert('A palavra-passe introduzida não é válida. Tente novamente');
      return false;
    }

    return true;
  }

  register() {
    if (!this.validateFields) {
      return;
    }

    this.enableSpinner();

    this.auth.register(this.emailField, this.passwordField, this.nameField, this.roles[this.selectedRoleIndex]).then(result => {
      this.disableSpinner();
      if (result) {
        alert('Utilizador registado com sucesso. Por favor contacto o departamento informático para pedir ativação da conta. Obrigado.');
        this.navigateToPage('login');
      } else {
        alert('Erro ao registar o utilizador. Verifique os dados e tente novamnte.\n\nCaso a situação persista, '
          + 'entre em contacto o departamento informático. Obrigado.');
      }

    }, error => {
      alert(error);
    });
  }

}
