import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-base',
  template: `
    <p>
      base works!
    </p>
  `,
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isLoading = false;

  constructor(protected router: Router) {
  }

  ngOnInit(): void {

  }

  enableSpinner() { this.isLoading = true; }
  disableSpinner() { this.isLoading = false; }


  navigateToPage(page) {
    this.router.navigateByUrl(page);
  }

}
