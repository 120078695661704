import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxAgoraModule } from 'ngx-agora';
import { VideoChatComponent } from './video-chat/video-chat.component';
import { HomeComponent } from './home/home.component';
import { SessionsComponent } from './sessions/sessions.component';
import { SessionComponent } from './sessions/session/session.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { HostSessionComponent } from './sessions/host-session/host-session.component';
import { AddSessionComponent } from './sessions/add-session/add-session.component';
import { BaseComponent } from './utils/base/base.component';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { SetupComponent } from './sessions/setup/setup.component';
import { HttpClientModule } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RegisterComponent } from './register/register.component';
import { MarketingComponent } from './marketing/marketing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    VideoChatComponent,
    HomeComponent,
    SessionsComponent,
    SessionComponent,
    LoginComponent,
    HostSessionComponent,
    AddSessionComponent,
    BaseComponent,
    SetupComponent,
    RegisterComponent,
    MarketingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [AngularFirestore, AngularFireStorage],
  bootstrap: [AppComponent]
})
export class AppModule { }
