<div class="d-flex justify-content-center align-self-center" style="margin-top:5px;">
  <div class="card" style="width: 400px;">
    <img src="../../assets/images/logo_clinica.jpg" alt="machadolemos" height=157>
    <div class="card-header">Preparação para sessão</div>

    <div class="card-body" *ngIf="session == undefined">
      {{hashMessage}}
    </div>

    <div *ngIf="hash != undefined && session != undefined">
      <!-- SESSION ENDED -->
      <div class="card-body" *ngIf="step == 5">
        <form>
          <h5>Sessão Terminada</h5>
          <p>Esta sessão já foi terminada pelo médico.
          </p>
          <p>Para mais informações, por favor entre em
            contacto conosco através dos números:</p>
          <p>252 618 016 ou 968 836 046</p>
          <p>Obrigado</p>
        </form>
      </div>
    </div>
  </div>
</div>