export class User {
  id: string;
  name: string;
  role: string[];
  email: string;
  active: boolean;

  token: string;

  constructor(firestoreObject?) {
    if (firestoreObject !== undefined) {
      this.id = firestoreObject.id;
      this.name = firestoreObject.data().name;
      this.role = firestoreObject.data().role;
      this.active = firestoreObject.data().active;
    } else {
      this.role = [];
      this.active = true;
    }
  }


  static importFromLocalStorage(json): User {
    if (json === undefined || json === null) {
      return undefined;
    }

    const user = new User();
    user.active = json.active;
    user.id = json.id;
    user.name = json.name;
    user.role = json.role;
    user.token = json.token;

    return user;
  }


  export(): object {
    const obj = Object.assign({}, this);
    Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});
    delete obj.id;
    delete obj.token;

    return obj;
  }
}
