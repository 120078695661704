<div style="margin: 0 auto;height:200px;width:509px;">
  <img src="../../assets/images/logo_clinica.jpg" alt="machadolemos" height=200>
</div>
<div class="card" style="width: 31.5em;margin:0 auto; margin-top: 0px;">
  <div class="card-body">
    <p class="h4" style="text-align: center;">Consultas Telemedicina</p>
    <form style="margin-top:20px;">
      <!-- <div class="form-group">
        <label for="inputNID">Número de Identificação Fiscal</label>
        <input [(ngModel)]="nifField" type="number" class="form-control" id="inputNID"
          placeholder="Introduza aqui o NIF" [ngModelOptions]="{standalone: true}">
      </div> -->
      <div class="form-group">
        <label for="inputPIN">Código de Acesso</label>
        <input [(ngModel)]="pinField" type="number" class="form-control" id="inputPIN"
          placeholder="Introduza aqui o código de acesso" [ngModelOptions]="{standalone: true}">
      </div>
      <div>
        <button type="submit" (click)="doEnter()" class="btn btn-primary" style="float:left;">Entrar na
          consulta</button>
        <a href="../../assets/documents/CONSULTAS_ML.pdf" target="_blank" class="btn btn-warning"
          style="float:right;">Como funciona?</a>
        <button type="submit" (click)="doSchedule()" class="btn btn-success" style="float: right">Marcar
          Consulta</button>
      </div>

    </form>
  </div>
</div>
<a href="/login"><button style="position: fixed; right:0; top:0;" class="btn btn-info btn-sm">Admin</button></a>